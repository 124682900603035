.bg-white {
  background-color: var(--white) !important;
}

.bg-tab-gray {
  background-color: var(--tab-gray) !important;
}
.play-preview.bg-tab-gray {
  background-color: var(--theme-light-blue) !important;
}
.bg-light-blue {
  background-color: var(--theme-light-blue) !important;
  &.btn {
    color: var(--white);
    &:hover {
      background-color: var(--theme-dark-blue) !important;
    }
  }
}
.bg-gray {
  background-color: var(--tab-gray) !important;
  &.btn {
    color: var(--theme-dark-blue);
    &:hover {
      color: var(--white);
      background-color: var(--theme-dark-blue) !important;
    }
  }
}

.bg-green {
  cursor: pointer;
  background-color: var(--theme-green) !important;
}

.bg-theme-orange {
  background-color: var(--theme-orange) !important;
  &.btn {
    color: var(--white);
    &:hover {
      background: var(--theme-light-blue) !important;
    }
  }
}

.dialog-box {
  .bg-theme-green {
    background-color: var(--theme-orange) !important;
    &.btn {
      color: var(--white);
      &:hover {
        background: var(--theme-light-blue) !important;
      }
    }
  }
}

.dialog-box {
  .bg-theme-green {
    background-color: var(--theme-green) !important;
    &.btn {
      color: var(--white);
      &:hover {
        background: var(--theme-light-blue) !important;
      }
    }
  }
}

.dialog-box.light-blue {
  .bg-theme-orange {
    background-color: var(--theme-orange) !important;
    &.btn {
      color: var(--white);
      &:hover {
        color: var(--theme-orange);
        background: var(--white) !important;
      }
    }
  }
}

.base-form-input {
  background: var(--white);
}

.m-input-theme-orange {
  .MuiInputBase-root.MuiInput-root {
    color: var(--white) !important;
    background: var(--theme-orange) !important;
    input {
      color: var(--white) !important;
    }
  }
}