$colors: (
  'global-light-text-gray': #757576,
  'white': #ffffff,
  'white-gray': #e1e1e1,
  'input-gray': #fafafb,
  'disabled-gray': #f5f6f7,
  'gray': #b0b0ad,
  'dark-gray': #acacac,
  'blue': #0681db,
  'highlight-blue': #82c0ed,
  'light-blue': #304775,
  'black-blue': #0b3b7f,
  'theme-light-blue': #193266,
  'theme-dark-blue': #001c58,
  'white-blue': #8ad6e8,
  'tab-dark-gray': #e6e6ed,
  'tab-gray': #e5e8ed,
  'default-hint': #D9D9D9,
  'correct-hint': #C1EDD5,
  'wrong-hint': #FFE8BB,
  'wrong-choice': #FF6E1D,
  'solitude-light': #f3f4f7,
  'theme-green': #30c474,
  'theme-orange': #c43c30,
  'theme-dark-orange': #9e281d,
  'theme-yellow': #ff9d00,
  "highlight-yellow": #ffbf00,
  'pelorous': #17a2b8,
  'off-white': #f5f6f7,
  'muted': #444444,
  'blue-disable': #26326e,
);

$global-light-text-gray: #757576;
$button-box: #e1e5eb;

$base-shadow: -5px -5px 10px 0 rgba(255, 255, 255, 1), 5px 5px 10px 0 rgba(0, 0, 0, 0.1);

$home-button-size: 5.834vw;
$page-head-height: $home-button-size;
$sidebar-gutter: 1.2vw;
$border-radius: 2vw;
$editor-spacing: 0.2vw;
$editor-radius: 0.6vw;
$button-radius: 0.7vw;

$main-panel-radius: 2vw;
$left: 0.8vw;
$right: 1.4vw;

$mobile-col-radius: 8vw;
$mobile-col-color: var(--theme-light-blue);
$mobile-background: var(--theme-dark-blue);
$mobile-arrow-color: #96a0b6;

$font-family-light: 'Brandon Grotesque Light';
$font-family-light-italic: 'Brandon Grotesque Light Italic';
$font-family-regular: 'Brandon Grotesque Regular';
$font-family-medium: 'Brandon Grotesque Medium';
$font-family-regular-italic: 'Brandon Grotesque Regular Italic';
$font-family-bold: 'Brandon Grotesque Bold';
$font-family-bold-italic: 'Brandon Grotesque Bold Italic';
$font-family-black: 'Brandon Grotesque Black';
$font-family-black-italic: 'Brandon Grotesque Black Italic';

$brick-radius: 1.2vh;
$main-panel-radius: 2vw;
$brick-radius: 1.2vh;
$main-panel-radius: 2vw;

$min-brick-height: 4.4vw;
$base-lg-text-size: 0.65vw;
$base-sm-text-size: 0.9vw;
$base-md-text-size: 1.1vw;
$bin-size: 1.3vw;
$answer-input-text-size: 1vw;

$height-mobile-view: calc(100vh - 45px);
$back-to-work-height-mobile-view: 100%;
$height-desktop-view: calc(100vh - 7.834vw);

$answer-height: 3.9vw;

$phone-width: 300px;
$phone-ladscape-width: 600px;
$tablet-portrait-width: 760px;
$tablet-landscape-width: 1024px;
$desktop-width: 1280px;
$laptop-landscape-width: 1366px;
$desktop-wide-width: 1440px;
$ex-desktop-wide-width: 1680px;
$ex-desktop-wide-hd-width: 1920px;
$apple-macbook-air-width: 2560px;
$apple-macbook-pro-width: 3072px;
$apple-imac-width: 5120px;

// dashboard and back to work page
$sorted-row-margin-top: 2vw;

$bricks-title-padding-top: 2.1vh;
$bricks-title-padding-bottom: 0.5vh;
$bricks-title-font-size: 5.2vw;

$pagination-size: 6vh;
$backwork-tab-height: 6.6vh;

$notification-width: 27.2vw;

$bricks-height: calc(
  100vh - #{$page-head-height} - #{$sorted-row-margin-top} - #{$bricks-title-font-size} - #{$bricks-title-padding-top} -
    #{$bricks-title-padding-bottom} - #{$pagination-size}
);

$backwork-bricks-padding-top: 5.5vh;

$backwork-bricks-height: calc(
  100vh - #{$page-head-height} - #{$sorted-row-margin-top} - #{$pagination-size} - #{$backwork-tab-height} - 1vh
);
$backwork-bricks-height-2: calc(
  100vh - #{$page-head-height} - #{$sorted-row-margin-top} - #{$pagination-size} - #{$backwork-tab-height} + 1.4vh - #{$backwork-bricks-padding-top}
);

$backwork-tab-content-height: calc(100vh - #{$page-head-height} - #{$sorted-row-margin-top} - #{$backwork-tab-height});

$backwork-inner-tab-content-height: calc(
  100vh - #{$page-head-height} - #{$sorted-row-margin-top} - #{$backwork-tab-height} - #{$pagination-size}
);

$filter-sidebar-size: 23vw;
$filter-sidebar-margin: 1.2vw;

// build suggestions sidebar
$suggestion-sidebar-height: 76vh;
$comment-button-size: 10vh;
$comments-panel-margin-top: 3vh;
$comments-panel-height: calc(#{$suggestion-sidebar-height} - #{$comments-panel-margin-top});

$suggestions-title-font-size: 3vh;
$suggestions-title-padding: 1vh;

$base-popup-font-size: 1.6vw;


$progress-bar-height: 26px;

// mobile
$mobile-menu-height: 17.5vw;
$mbase-font-size: 5vw;
