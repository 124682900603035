@use "sass:math";

.flex {
	display: flex !important;
}

.flex-inline {
	display: inline-flex !important;
}

.f-fill {
	> div,
	> a,
	> ul,
	> li,
	> p,
	> span,
	> input {
		flex: 1 !important;
	}
}

.f-auto {
	> div,
	> a,
	> ul,
	> p,
	> span,
	> input {
		flex: auto !important;
	}
}

.f-wrap {
	flex-wrap: wrap !important;
}

.f-wrap-reverse {
	flex-wrap: wrap-reverse !important;
}

.f-nowrap {
	flex-wrap: nowrap !important;
}

.f-row {
	flex-direction: row !important;
}

.f-row-reverse {
	flex-direction: row-reverse !important;
}

.f-column {
	flex-direction: column !important;
}

.f-column-reverse {
	flex-direction: column-reverse !important;
}

.f-content-baseline {
	align-content: baseline !important;
}

.f-content-center {
	align-content: center !important;
}

.f-content-end {
	align-content: flex-end !important;
}

.f-content-start {
	align-content: flex-start !important;
}

.f-content-start {
	align-content: flex-start !important;
}

.f-content-around {
	align-content: space-around !important;
}

.f-content-between {
	align-content: space-between !important;
}

.f-row-reverse {
	flex-direction: row-reverse !important;
}

.f-row {
	flex-direction: row !important;
}

.f-justify-start {
	justify-content: flex-start !important;
}

.f-justify-center {
	justify-content: center !important;
}

.f-justify-end {
	justify-content: flex-end !important;
}

.f-justify-between {
	justify-content: space-between !important;
}

.f-justify-around {
	justify-content: space-around !important;
}

.f-align-start {
	align-items: flex-start !important;
}

.f-align-center {
	align-items: center !important;
}

.f-align-end {
	align-items: flex-end !important;
}

.f-align-baseline {
	align-items: baseline !important;
}

.f-align-stretch {
	align-items: stretch !important;
}

.f-align-self-start {
	align-self: flex-start !important;
}

.f-align-self-center {
	align-self: center !important;
}

.f-align-self-end {
	align-self: flex-end !important;
}

.f-align-self-baseline {
	align-self: baseline !important;
}

.f-align-self-stretch {
	align-self: stretch !important;
}

.f-justify-self-start {
	justify-self: flex-start !important;
}

.f-justify-self-center {
	justify-self: center !important;
}

.f-justify-self-end {
	justify-self: flex-end !important;
}

.f-justify-self-baseline {
	justify-self: baseline !important;
}

.f-justify-self-stretch {
	justify-self: stretch !important;
}

.f-flow-column-wrap {
	flex-flow: column wrap !important;
}

.f-flow-column-reverse-wrap {
	flex-flow: column-reverse wrap !important;
}

.f-flow-row-wrap {
	flex-flow: row wrap !important;
}

.f-flow-row-reverse-wrap {
	flex-flow: row-reverse wrap !important;
}

.f-flow-column-wrap-reverse {
	flex-flow: column wrap-reverse !important;
}

.f-flow-column-reverse-wrap-reverse {
	flex-flow: column-reverse wrap-reverse !important;
}

.f-flow-row-wrap-reverse {
	flex-flow: row wrap-reverse !important;
}

.f-flow-row-reverse-wrap-reverse {
	flex-flow: row-reverse wrap-reverse !important;
}

.f-grow-1 {
	flex-grow: 1;
}

.f-shrink-1 {
	flex-shrink: 1;
}

@for $value from 1 through 100 {
	.f-#{$value} {
		flex-basis: percentage(math.div($value, 100)) !important;
	}
}

@for $value from 1 through 20 {
	.order-#{$value} {
		order: $value !important;
	}
}

@for $value from 1 through 50 {
	.f-gap#{$value} {
		margin-left: #{"-" + $value + "px"};
		margin-right: #{"-" + $value + "px"};
		> div {
			margin-left: #{$value + "px"};
			margin-right: #{$value + "px"};
		}
	}
}

.invinsible {
	height: 0;
	overflow: hidden;
}

.base-hint-container .invinsible {
	display: none !important;
}

.base-hint-container {
	.customImage.image-play-container2.center {
		display: flex;
		width: 100%;
	}
}