@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

@mixin animation($animation) {
  -webkit-animation: $animation;
  -moz-animation: $animation;
  -ms-animation: $animation;
  -o-animation: $animation;
  animation: $animation;
}

@mixin animation-dual($animation1, $animation2) {
  -webkit-animation: $animation1, $animation2;
  -moz-animation: $animation1, $animation2;
  -ms-animation: $animation1, $animation2;
  -o-animation: $animation1, $animation2;
  animation: $animation1, $animation2;
}

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}