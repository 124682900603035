@import 'src/assets/sass/partials/variables';
@import 'src/assets/sass/partials/mixins';
@import 'src/assets/sass/partials/extensions';

.beta-text {
  width: fit-content;
  position: fixed;
  bottom: 2.4vh;
  right: 2.4vh;
  color: var(--theme-orange);
  font-size: 2vw;
  line-height: 2vw;
  letter-spacing: 0.4vw;
  font-family: $font-family-black;
  @include flexCenter;
  @include phone_and_tabletP {
    min-height: 5.3vw;
  }
  @include phone {
    font-size: 6vw;
  }
  @include tabletP {
    font-size: 2vw;
  }
  div {
    display: inline-block;
  }

  .version-number {
    width: 0.5vw;
    font-size: 0.5vw;
    writing-mode: vertical-lr;
    text-orientation: mixed;
    letter-spacing: 0.08vw;
    @include flexCenter;
    transform: scale(-1, -1);
    @include phone_and_tabletP {
      width: 2vw;
      font-size: 1.5vw;
    }
  }
}

.MuiGrid-align-content-xs-center.MuiGrid-justify-xs-center {
  display: -webkit-inline-box !important;
  -webkit-box-align: center !important;
  -webkit-box-pack: center !important;
}

.MuiGrid-align-content-xs-center.MuiGrid-justify-xs-flex-end {
  -webkit-box-pack: end !important;
}

#webWidget {
  left: 0;
  width: 100% !important;
  @include desktop {
    width: 357px !important;
  }
}

.youtube-link {
  margin-top: 1vw;
  height: 3vw;

  > a {
    border-radius: 1vw;
    font-size: 1.7vw;
    background: var(--theme-orange) !important;
    color: white !important;
    padding-top: 1.1vh;
    padding-bottom: 1.1vh;
    padding-left: 1vw;
    padding-right: 1vw;
  }
}

.wrs_focusElementContainer {
  background: red;
}

iframe {
  max-width: 100vw;
}

.MuiFormHelperText-root.Mui-error {
  font-family: $font-family-bold;
  color: white !important;
  font-size: 1vw;
}

.text-transform-none {
  text-transform: none !important;
}