html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, a,
abbr, acronym, address, big, cite, code, del,
dfn, em, font, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center,
dl, dt, dd, ol, ul, li, fieldset, form, label, legend,
input, select, table, caption, tbody, tfoot, thead, tr,
th, td, article, aside, canvas, details, embed, figure,
figcaption, footer, header, hgroup, menu, nav, output,
ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	border-collapse: collapse;
	outline: none;
	background: none;
	text-decoration: none;
	border-spacing: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

ul, ol {
  list-style-position: inside;
}

abbr[title],
abbr[data-original-title] {
	cursor: help;
	border-bottom-width: 1px;
	border-bottom-style: dotted;
}

a {
	background: transparent;
	outline: none;

	&.link {
		color: var(--blue);
		text-decoration: underline;
		@include p0;
		@include m0;
	}

	&:focus,
	&:active,
	&:hover {
		color: var(--black);
		outline: none;
	}
}

em,
dfn {
	font-style: italic;
}

u {
	text-decoration: underline;
}

:focus {
	outline: 0;
}

figure {
	margin: 0;
}

table {
	border-collapse: separate;
	border-spacing: 0;
}

caption,
th,
td {
	font-weight: normal;
}

q,
blockquote {
	quotes: "\201C""\201D""\2018""\2019";
	&:before,
	&:after {
		content: "";
	}
}

a,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	cursor: pointer;
}

input,
button,
select,
textarea {
	font-size: inherit;
	line-height: inherit;
}

input {
	line-height: normal;
}

div:focus {
  outline: none;
}

input,
textarea {
  &:focus {
    outline: none;
  }
}

button,
optgroup,
select,
textarea {
	margin: 0;
	color: inherit;
	border-radius: 5px;
}

button {
	overflow: visible;
}

button,
select {
	text-transform: none;
}
