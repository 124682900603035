@use "sass:math";

:root {
	@each $color, $value in $colors {
		$dashColor: "--" + $color;
		@for $i from 1 through 10 {
			@if $i == 10 {
				#{$dashColor}: #{$value};
			} @else {
				#{$dashColor}-alpha#{$i}: rgba($value, math.div($i,10));
			}
		}
	}

	--monospace: "Inconsolata", monospace, SFMono-Regular, Menlo, Monaco, Consolas, Courier, "Liberation Mono", "Courier New";
}
