// 300
@mixin phone {
  @media screen and (min-width:#{$phone-width}) {
    @content;
  }
}
// 759 max
@mixin phone_and_tabletP {
  @media screen and (max-width:#{$tablet-portrait-width - 1}) {
    @content;
  }
}

// 760
@mixin tabletP {
  @media screen and (min-width:#{$tablet-portrait-width}) {
    @content;
  }
}

// 1023 max
@mixin phone_and_tabletL {
  @media screen and (max-width:#{$tablet-landscape-width - 1}) {
    @content;
  }
}

// 1024
@mixin tabletL {
  @media screen and (min-width:#{$tablet-landscape-width}) {
    @content;
  }
}

// 1280
@mixin desktop {
  @media screen and (min-width:#{$desktop-width}) {
    @content;
  }
}

// 1366
@mixin laptop {
  @media screen and (min-width:#{$laptop-landscape-width}) {
    @content;
  }
}

// 1440
@mixin desktop_wide {
  @media screen and (min-width:#{$desktop-wide-width}) {
    @content;
  }
}

// 1680
@mixin ex-desktop_wide {
  @media screen and (min-width:#{$ex-desktop-wide-width}) {
    @content;
  }
}

// 1920
@mixin ex-desktop_wide_hd {
  @media screen and (min-width:#{$ex-desktop-wide-hd-width}) {
    @content;
  }
}

// 2560
@mixin apple-macbook-air-width {
  @media screen and (min-width:#{$apple-macbook-air-width}) {
    @content;
  }
}

// 3072
@mixin apple-macbook-pro-width {
  @media screen and (min-width:#{$apple-macbook-pro-width}) {
    @content;
  }
}

// 5120
@mixin apple-imac-width {
  @media screen and (min-width:#{$apple-imac-width}) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}
