@mixin lhInherit {
  line-height: inherit !important;
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin widthAuto {
  width: auto;
}
@mixin width100 {
  width: 100%;
}
@mixin maxWidth100 {
  max-width: 100%;
}

@mixin heightAuto {
  height: auto;
}
@mixin height100 {
  height: 100%;
}

@mixin posRelative {
  position: relative;
}
@mixin posAbsolute {
  position: absolute;
}
@mixin posFixed {
  position: fixed;
}

@mixin fontInherit {
  font-family: inherit;
}

@mixin uppercase {
  text-transform: uppercase;
}

@mixin fontFamilyLight {
  font-family: 'Brandon Grotesque Light';
}
@mixin fontFamilyLightItalic {
  font-family: 'Brandon Grotesque Light Italic';
}
@mixin fontFamilyRegular {
  font-family: 'Brandon Grotesque Regular';
}
@mixin fontFamilyRegularItalic {
  font-family: 'Brandon Grotesque Regular Italic';
}
@mixin fontFamilyBold {
  font-family: 'Brandon Grotesque Bold';
}
@mixin fontFamilyBoldItalic {
  font-family: 'Brandon Grotesque Bold Italic';
}
@mixin fontFamilyBlack {
  font-family: 'Brandon Grotesque Black';
}
@mixin fontFamilyBlackItalic {
  font-family: 'Brandon Grotesque Black Italic';
}

@mixin fs9 {
  font-size: 9px;
}
@mixin fs10 {
  font-size: 10px;
}
@mixin fs11 {
  font-size: 11px;
}
@mixin fs12 {
  font-size: 12px;
}
@mixin fs13 {
  font-size: 13px;
}
@mixin fs14 {
  font-size: 14px;
}
@mixin fs15 {
  font-size: 15px;
}
@mixin fs16 {
  font-size: 16px;
}
@mixin fs17 {
  font-size: 17px;
}
@mixin fs18 {
  font-size: 18px;
}
@mixin fs19 {
  font-size: 19px;
}
@mixin fs20 {
  font-size: 20px;
}
@mixin fs21 {
  font-size: 21px;
}
@mixin fs22 {
  font-size: 22px;
}
@mixin fs23 {
  font-size: 23px;
}
@mixin fs24 {
  font-size: 24px;
}
@mixin fs25 {
  font-size: 25px;
}
@mixin fs30 {
  font-size: 30px;
}

@mixin fw-3 {
  font-weight: 300;
}
@mixin fw-4 {
  font-weight: 400;
}
@mixin fw-5 {
  font-weight: 500;
}
@mixin fw-6 {
  font-weight: 600;
}
@mixin fw-7 {
  font-weight: 700;
}
@mixin fw-8 {
  font-weight: 800;
}
@mixin fw-9 {
  font-weight: 900;
}

@mixin noWrap {
  white-space: nowrap !important;
}

@mixin displayFlex {
  display: flex;
}
@mixin flexNone {
  flex: none;
}
@mixin flexRow {
  flex-direction: row;
}
@mixin flexColumn {
  flex-direction: column;
}
@mixin flexJustifyCenter {
  justify-content: center;
}
@mixin flexAlignStart {
  align-items: flex-start;
}
@mixin flexAlignCenter {
  align-items: center;
}

@mixin flexAlignEnd {
  align-items: flex-end;
}
@mixin flexNowrap {
  flex-wrap: nowrap;
}
@mixin flexWrap {
  flex-wrap: wrap;
}

@mixin flexFlowColumn {
  flex-flow: column wrap;
}
@mixin flexFlowColReverse {
  flex-flow: column-reverse wrap;
}
@mixin flexFlowRow {
  flex-flow: row wrap;
}
@mixin flexFlowRowReverse {
  flex-flow: row-reverse wrap;
}
@mixin flexFlowWrapReverse {
  flex-flow: wrap-reverse;
}

@mixin noUserSelect {
  @include user-select(none);
}
@mixin noDisplay {
  display: none;
}
@mixin noShadow {
  box-shadow: none;
}
@mixin noOutline {
  outline: none;
}
@mixin nolistStyle {
  list-style: none;
}
@mixin noTextDecor {
  text-decoration: none;
}

@mixin cursorDisabled {
  pointer-events: none;
}

@mixin cursorPointer {
  cursor: pointer;
}

@mixin noPointerEvent {
  pointer-events: none;
}

@mixin noBg {
  background: none;
}
@mixin bgTransparent {
  background-color: transparent;
}

@mixin noRad {
  border-radius: 0;
}
@mixin rad40 {
  border-radius: 40px;
}
@mixin radCircle {
  border-radius: 50%;
}
@mixin themeRadius {
  border-radius: $editor-radius;
}

@mixin op0 {
  @include opacity(0);
}
@mixin op03 {
  @include opacity(0.3);
}
@mixin op04 {
  @include opacity(0.4);
}
@mixin op05 {
  @include opacity(0.5);
}
@mixin op1 {
  @include opacity(1);
}

@mixin b0 {
  border: 0;
}
@mixin b1 {
  border-width: 1px;
  border-style: solid;
}
@mixin bb1 {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
@mixin br1 {
  border-right-width: 1px;
  border-right-style: solid;
}
@mixin bl1 {
  border-left-width: 1px;
  border-left-style: solid;
}

@mixin shDefault {
  box-shadow: 0 0px 3px 0 var(--black-alpha2);
}
@mixin shActive {
  box-shadow: 0px 2px 5px var(--black-alpha2);
}
@mixin sh14 {
  box-shadow: 0 2px 10px var(--black-alpha2);
}

@mixin cardDefault {
  background-color: var(--gray);
}
@mixin cardActive {
  background-color: var(--gray);
}

@mixin borderGray {
  border-color: var(--gray);
}
@mixin borderGrayDark {
  border-color: var(--dark-gray);
}

@mixin borderCollapse {
  outline: none;
}

@mixin overflowHidden {
  overflow: hidden;
}

@mixin removeAppearence {
  @include appearance(none);
}

@mixin removeAnimation {
  animation: none !important;
}

@mixin p0 {
  padding: 0px;
}
@mixin p3 {
  padding: 3px;
}
@mixin p5 {
  padding: 5px;
}
@mixin p6 {
  padding: 6px;
}
@mixin p7 {
  padding: 7px;
}
@mixin p10 {
  padding: 10px;
}
@mixin p13 {
  padding: 13px;
}
@mixin p14 {
  padding: 14px;
}
@mixin p15 {
  padding: 15px;
}
@mixin p20 {
  padding: 20px;
}
@mixin p28 {
  padding: 28px;
}
@mixin marginAuto {
  margin: auto;
}
@mixin m0 {
  margin: 0px;
}
@mixin m5 {
  margin: 5px;
}
@mixin m7 {
  margin: 7px;
}
@mixin m10 {
  margin: 10px;
}
@mixin m15 {
  margin: 15px;
}
@mixin m20 {
  margin: 20px;
}

@mixin mb5 {
  margin-bottom: 5px;
}

@mixin textCenter {
  text-align: center;
}
@mixin textRight {
  text-align: right;
}
@mixin textLeft {
  text-align: left;
}

@mixin textUppercase {
  text-transform: uppercase;
}

@mixin pullLeft {
  float: left;
}
@mixin pullRight {
  float: right;
}
@mixin pullNone {
  float: none;
}

@mixin transitionAll150 {
  @include transition(all 150ms cubic-bezier(0.5, 1, 0.89, 1));
}
@mixin transitionAll300 {
  @include transition(all 300ms cubic-bezier(0.5, 1, 0.89, 1));
}
@mixin transitionAll400 {
  @include transition(all 400ms cubic-bezier(0.5, 1, 0.89, 1));
}
@mixin transitionAll500 {
  @include transition(all 500ms cubic-bezier(0.5, 1, 0.89, 1));
}
@mixin transitionAll600 {
  @include transition(all 600ms cubic-bezier(0.5, 1, 0.89, 1));
}

@mixin floatToCenter {
  @include posAbsolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@mixin floatToXCenter {
  @include posAbsolute;
  left: 50%;
  transform: translateX(-50%);
}
@mixin floatToYCenter {
  @include posAbsolute;
  top: 50%;
  transform: translateY(-50%);
}
@mixin flexCenter {
  @include displayFlex;
  @include flexAlignCenter;
  @include flexJustifyCenter;
}
@mixin flexToYCenter {
  @include displayFlex;
  @include flexAlignCenter;
}
@mixin flexToXCenter {
  @include displayFlex;
  @include flexJustifyCenter;
}

@mixin radioBlue {
  .MuiIconButton-root {
    &.MuiRadio-colorSecondary {
      &:hover {
        background-color: var(--theme-dark-blue-alpha1);
      }
      &.Mui-checked {
        color: var(--theme-dark-blue);
        &:hover {
          background-color: var(--theme-dark-blue-alpha1);
        }
      }
    }
  }
}

@mixin checkboxValidation {
  .MuiSvgIcon-root {
    color: var(--gray) !important;
    &:hover {
      background-color: var(--gray-alpha4) !important;
    }
  }
  &.checkbox-invalid {
    color: var(--theme-orange) !important;
    &:hover {
      background-color: var(--theme-orange-alpha4) !important;
    }
    svg {
      color: var(--theme-orange) !important;
    }
  }
  &.Mui-checked {
    color: var(--theme-green) !important;
    &:hover {
      background-color: var(--theme-green-alpha4) !important;
    }
    svg {
      color: var(--theme-green) !important;
    }
  }
}

@mixin inputContainer {
  @include flexCenter;
  flex-direction: column;
  @include phone_and_tabletL {
    background-color: var(--solitude-light);
    border-radius: 5.1vw;
    padding: 1vh 2vh 0;
    margin: 4vh auto;
    height: 15vh;
  }
  @include tabletL {
    margin: 6vw auto;
    width: 40vw !important;
    font-size: 1.4vw !important;
    border: transparent;
  }
}
@mixin buildInputs {
  @include posRelative;
  width: 100%;
  margin: 1vw 0;
  .MuiInput-underline {
    width: 100%;
    color: var(--theme-dark-blue);
    &:before,
    &:after {
      display: none !important;
    }
  }
  &:after,
  &:before {
    content: '';
    @include floatToXCenter;
    bottom: 0;
    pointer-events: none;
    transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 1px;
    height: 2px;
  }
  &:before {
    width: 0;
    background-color: var(--theme-dark-blue);
    z-index: 1;
  }
  &:after {
    width: 100%;
    background-color: var(--tab-gray);
    z-index: 0;
  }
  &:hover,
  &:focus-within {
    &:before {
      width: 100%;
    }
  }
  input,
  textarea {
    width: 100%;
    padding: 0 0 0.8vw 0;
    border: 0;
    @include phone {
      font-size: 6.3vw;
    }
    @include tabletP {
      font-size: 3.3vw;
    }
    @include tabletL {
      font-size: 1.6vw;
    }
  }
}

@mixin tutorialPanel {
  &.tutorial-panel {
    .tutorial-step-1 {
      margin: 0 3vw;
      margin-top: 3.3vh;
      background: var(--off-white);
      color: var(--theme-dark-blue);
      border-radius: 0.7vw;
      height: 72vh;
      overflow: auto;
      h1 {
        margin-top: 1vh;
        margin-left: 2vw;
        margin-bottom: 1.8vh;
        font-size: 3.05vw;
        font-family: $font-family-bold;
      }
      .editor-border {
        margin: 2vw 1vw 0 1vw;
        padding: 1.5vw;
        width: 8vw;
        height: 8vw;
        position: relative;
        border: 0;
        border-radius: 50%;
        @include flexCenter;
        .svg {
          position: unset;
          top: unset;
          left: unset;
          transform: unset;
        }
        .circle-border {
          width: 8vw !important;
          height: 8vw !important;
          color: var(--theme-orange) !important;
          position: absolute !important;
        }
      }
      .center {
        margin-top: 2.3vh;
        padding: 0;
        font-size: 1.28vw;
        @include textCenter;
      }
      .center-second {
        margin: 0;
        padding: 0;
        font-size: 1.28vw;
        @include textCenter;
      }
      .proposal-box {
        margin-left: 1vw;
        h2 {
          margin: 4.1vh 0 0 0vw;
          padding: 0;
          font-family: $font-family-bold;
          font-size: 2.1vw;
        }
        p {
          margin: 0;
          font-size: 1.5vw;
        }
        .last-text {
          margin-top: 3.5vh;
        }
      }
    }
    .bold {
      font-family: $font-family-bold;
    }
    .button-row {
      margin-top: 2vh;
      color: var(--theme-dark-blue);
      font-size: 1.5vw;
      button {
        cursor: pointer;
        background: white;
        color: var(--theme-dark-blue);
        border-radius: $editor-radius;
        font-size: 1.5vw;
        line-height: 1.36;
        padding: 0 0.8vw;
        font-family: $font-family-bold;
        border: 0.1vw solid var(--theme-dark-blue);
        transition: all 0.2s ease-in-out;
        &:hover {
          background: var(--theme-dark-blue);
          color: white;
        }
      }
      .left-arrow,
      .right-arrow {
        cursor: pointer;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center;
        height: 2.5vw;
        width: 2.5vw;
      }
      .left-arrow {
        background-image: url('../../public/feathericons/chevron-left-orange.png');
        margin-left: 1.8vw;
        margin-right: 1.3vw;
      }
      .right-arrow {
        background-image: url('../../public/feathericons/chevron-right-orange.png');
        margin-right: 2vw;
      }
    }
  }
}

@mixin linkDescription {
  .link-description {
    font-family: $font-family-bold;
    margin-bottom: 0.5vh;
    color: var(--theme-dark-blue);
    margin: 0;
    @include ellipsis;
    font-size: 1.1vw;
  }
}

@mixin questionLinkDescription {
  .link-description {
    @include textCenter;
    font-family: $font-family-regular;
    margin: 0;
    @include phone_and_tabletP {
      font-size: 3vw;
      line-height: 3.2vw;
    }
    @include tabletP {
      font-size: 1.4vw;
      line-height: 2vw;
    }
  }
}

@mixin removePosition {
  position: unset !important;
  top: unset !important;
  left: unset !important;
  transform: unset !important;
}

@mixin arrow-top-bottom {
  .top-scroll-area,
  .bottom-scroll-area {
    position: absolute;
    width: 100%;
    height: 5vh;
    @include flexCenter;
    .top-button-container,
    .bottom-button-container {
      position: relative;
      width: 100%;
      height: 100%;
      @include flexCenter;
      justify-content: flex-end;
      > button {
        @include floatToCenter;
        .svg {
          $scroll-button-size: 2vw;
          height: $scroll-button-size;
          width: $scroll-button-size;
          &.disabled {
            color: var(--tab-gray) !important;
          }
        }
      }
    }
    .scroll-text {
      @include flexCenter;
      @include flexCenter;
      span {
        font-size: 1vw;
        color: var(--theme-dark-blue);
      }
      button {
        width: 1.1vw;
        height: 1.1vw;
        margin: 0 0.4vw;
        > svg {
          width: inherit;
          height: inherit;
        }
      }
    }
  }
  .top-scroll-area,
  .bottom-scroll-area {
    .top-button-container,
    .bottom-button-container {
      max-width: 45%;
      min-width: 45%;
    }
  }
  .bottom-scroll-area {
    bottom: 0;
  }
}
