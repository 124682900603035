.w60 {
  width: 60% !important;
}
.h60 {
  height: 60% !important;
}
.w80 {
  width: 80% !important;
}
.h80 {
  height: 80% !important;
}
.w100 {
  width: 100% !important;
}
.h100 {
  height: 100% !important;
}

.w-2 {
  width: 2vw !important;
}
.w-3 {
  width: 3vw !important;
}
.w-4 {
  width: 4vw !important;
}
.w-5 {
  width: 5vw !important;
}
.h-2 {
  height: 2vw !important;
}
.h-3 {
  height: 3vw !important;
}
.h-4 {
  height: 4vw !important;
}
.h-5 {
  height: 5vw !important;
}
.m-0 {
  margin: 0 !important;
}
.m-l-1 {
  margin-left: 1vw !important;
}
.m-l-012 {
  margin-left: 1.2vw !important;
}
.m-t-2vh {
  margin-top: 2vh !important;
}
.m-t-3vh {
  margin-top: 3vh !important;
}
.m-t-5 {
  margin-top: 5vw !important;
}
.f-s-2 {
  font-size: 1.4vw;
}
.m-t-10 {
  margin-top: 10vw !important;
}
.m-l-02 {
  margin-left: 0.2vw !important;
  @include phone_and_tabletP {
    margin-left: 1vw !important;
  }
}
.m-l-03 {
  margin-left: 0.3vw !important;
}
.m-r-02 {
  margin-right: 0.2vw !important;
}
.m-r-04 {
  margin-right: 0.4vw !important;
}
.m-b-10 {
  margin-bottom: 1vw !important;
}
.m-b-05 {
  margin-bottom: 0.5vw !important;
}
.m-b-03 {
  margin-bottom: 0.3vw !important;
}
.m-b-02 {
  margin-bottom: 0.2vw !important;
}
.w-3 {
  width: 3vw;
}
