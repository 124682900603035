// @include font-face('./assets/fonts/BrandonGrotesque/', 'Brandon_thin');

@font-face {
	font-family: 'Brandon Grotesque Thin';
	font-style: normal;
	font-weight: normal;
	src:url("https://use.typekit.net/af/a96fa9/000000000000000077359de4/30/l?subset_id=2&fvd=n1&v=3") format("woff2"),url("https://use.typekit.net/af/a96fa9/000000000000000077359de4/30/d?subset_id=2&fvd=n1&v=3") format("woff"),url("https://use.typekit.net/af/a96fa9/000000000000000077359de4/30/a?subset_id=2&fvd=n1&v=3") format("opentype");
}

@font-face {
	font-family: 'Brandon Grotesque Thin Italic';
	font-style: normal;
	font-weight: normal;
	src:url("https://use.typekit.net/af/9270e6/000000000000000077359dec/30/l?subset_id=2&fvd=i1&v=3") format("woff2"),url("https://use.typekit.net/af/9270e6/000000000000000077359dec/30/d?subset_id=2&fvd=i1&v=3") format("woff"),url("https://use.typekit.net/af/9270e6/000000000000000077359dec/30/a?subset_id=2&fvd=i1&v=3") format("opentype");
}

@font-face {
	font-family: 'Brandon Grotesque Light';
	font-style: normal;
	font-weight: normal;
	src:url("https://use.typekit.net/af/1281a1/000000000000000077359ded/30/l?subset_id=2&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/1281a1/000000000000000077359ded/30/d?subset_id=2&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/1281a1/000000000000000077359ded/30/a?subset_id=2&fvd=n3&v=3") format("opentype");
}

@font-face {
	font-family: 'Brandon Grotesque Light Italic';
	font-style: normal;
	font-weight: normal;
	src:url("https://use.typekit.net/af/08312f/000000000000000077359dee/30/l?subset_id=2&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/08312f/000000000000000077359dee/30/d?subset_id=2&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/08312f/000000000000000077359dee/30/a?subset_id=2&fvd=i3&v=3") format("opentype");
}

@font-face {
	font-family: 'Brandon Grotesque Regular';
	font-style: normal;
	font-weight: normal;
	src:url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
}

@font-face {
	font-family: 'Brandon Grotesque Regular Italic';
	font-style: normal;
	font-weight: normal;
	src:url("https://use.typekit.net/af/32d3ee/0000000000000000000132e0/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/32d3ee/0000000000000000000132e0/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/32d3ee/0000000000000000000132e0/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
}

@font-face {
	font-family: 'Brandon Grotesque Medium';
	font-style: normal;
	font-weight: normal;
	src:url("https://use.typekit.net/af/d03e48/000000000000000077359df2/30/l?subset_id=2&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/d03e48/000000000000000077359df2/30/d?subset_id=2&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/d03e48/000000000000000077359df2/30/a?subset_id=2&fvd=n5&v=3") format("opentype");
}

@font-face {
	font-family: 'Brandon Grotesque Medium Italic';
	font-style: normal;
	font-weight: normal;
	src:url("https://use.typekit.net/af/b59a99/000000000000000077359df3/30/l?subset_id=2&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/b59a99/000000000000000077359df3/30/d?subset_id=2&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/b59a99/000000000000000077359df3/30/a?subset_id=2&fvd=i5&v=3") format("opentype");
}

@font-face {
	font-family: 'Brandon Grotesque Bold';
	font-style: normal;
	font-weight: normal;
	src:url("https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
}

@font-face {
	font-family: 'Brandon Grotesque Bold Italic';
	font-style: normal;
	font-weight: normal;
	src:url("https://use.typekit.net/af/383ab4/0000000000000000000132e4/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/383ab4/0000000000000000000132e4/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/383ab4/0000000000000000000132e4/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
}

@font-face {
	font-family: 'Brandon Grotesque Black';
	font-style: normal;
	font-weight: normal;
	src:url("https://use.typekit.net/af/257c86/000000000000000077359df6/30/l?subset_id=2&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/257c86/000000000000000077359df6/30/d?subset_id=2&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/257c86/000000000000000077359df6/30/a?subset_id=2&fvd=n9&v=3") format("opentype");
}

@font-face {
	font-family: 'Brandon Grotesque Black Italic';
	font-style: normal;
	font-weight: normal;
	src:url("https://use.typekit.net/af/037411/000000000000000077359df7/30/l?subset_id=2&fvd=i9&v=3") format("woff2"),url("https://use.typekit.net/af/037411/000000000000000077359df7/30/d?subset_id=2&fvd=i9&v=3") format("woff"),url("https://use.typekit.net/af/037411/000000000000000077359df7/30/a?subset_id=2&fvd=i9&v=3") format("opentype");
}

.italic {
  font-family: $font-family-regular-italic;
}

@font-face{
  font-family: text-security-disc;
  src: url("https://raw.githubusercontent.com/noppa/text-security/master/dist/text-security-disc.woff");
}