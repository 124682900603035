.text-white {
  color: var(--white) !important;
}
.text-theme-green {
  color: var(--theme-green) !important;
}
.text-orange {
  color: var(--theme-orange);
}
.text-white-gray {
  color: var(--white-gray);
}
.text-theme-orange {
  color: var(--theme-orange) !important;
}
.text-tab-gray {
  color: var(--tab-gray) !important;
}
.text-white-blue {
  color: var(--white-blue) !important;
}
.text-blue {
  color: var(--text-blue) !important;
}
.text-theme-light-blue {
  color: var(--theme-light-blue) !important;
}
.text-theme-dark-blue {
  color: var(--theme-dark-blue) !important;
}
.text-gray {
  color: var(--gray) !important;
}
.text-dark-gray {
  color: var(--dark-gray) !important;
}

.text-yellow {
  color: var(--theme-yellow) !important;
}
.b-red {
  background: var(--theme-orange) !important;
}
.b-yellow {
  background: var(--theme-yellow) !important;
}
.b-yellow-50 {
  background-color: var(--theme-yellow) 50% !important;
}
.b-green {
  background: var(--theme-green) !important;
}
.b-white {
  background: var(--white) !important;
}
.b-dark-blue {
  background: var(--theme-dark-blue) !important;
}
.b-tab-gray {
  background: var(--tab-gray) !important;
}

.MuiButtonBase-root.MuiIconButton-root.MuiRadio-root.white {
  color: white;
  height: 3.5vw;
  width: 3.5vw;
  padding: 0 !important;

  span {
    padding: 0 !important;
    margin: 0 !important;
    height: inherit;
    width: inherit;
  }

  svg {
    margin: 0 !important;
    height: 3vw !important;
    width: 3vw !important;
  }

  input {
    margin: 0 !important;
    padding: 0 !important;
    height: inherit !important;
    width: inherit !important;
  }
}

.alert-icon {
  color: white;
  background-color: var(--theme-orange) !important;
}

.blue-disable {
  color: var(--blue-disable) !important;
}

.orange-theme-circle {
  border: 0.2vw solid var(--theme-orange) !important;
  background: inherit !important;
}